
import { Text, Flex, Heading, View } from '@aws-amplify/ui-react';
import { Authenticator, CheckboxField, SelectField   } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAllEventsAllLocations } from "../../helpers/eventsHelpers";

const SignUp = ({ location }) => {
  const navigate = useNavigate();
  const [GroupsChecked, setGroupsChecked] = React.useState(false);
  const [locationSet, setlocationSet] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [AllEventsTitles, setAllEventsTitles] = React.useState([]);
    
  async function fetchLocation() {
    var loc = searchParams.get('loc');
    if (loc) {setlocationSet(loc);}
 }

  useEffect(() => {
    fetchLocation();
  }, [location]);

  const handleChange = (e) => {
    getAllEventsAllLocations(false).then(response => {
      setAllEventsTitles(response.map(value => value.title));
      if (GroupsChecked) {
        setGroupsChecked(false)
      } else {
        setGroupsChecked(true)
      }
      return;
    });

  };

  
  return (
    <Container fluid className="signin signup">
      <Row>
        <Col sm={12} lg={6} className="left">
          <View className="inner">
          <Heading level={1} className="text-center">Sign Up</Heading>
          { (locationSet) ? <Text>To purchase a ticket you must first create an account!</Text> : null }
          <Authenticator
            // Default to Sign Up screen
            initialState="signUp"
            // Customize `Authenticator.SignUp.FormFields`
            components={{
              SignUp: {
                FormFields() {

                  return (
                    <>

                    {/* Append & require Terms and Conditions field to sign up  */}
                    <CheckboxField
                      name="part_of_group"
                      value="yes"
                      label="Are you part of a fundraising group?"
                      onChange={handleChange}
                      checked={GroupsChecked}
                    />
                    { GroupsChecked ?
                    <SelectField
                      name="custom:fundraiser"
                      label="Choose your fundraiser"
                      options={AllEventsTitles}
                    ></SelectField> : null }
                      {/* Re-use default `Authenticator.SignUp.FormFields` */}
                      <Authenticator.SignUp.FormFields />
                    </>
                  );
                },
              },
            }}>
            
            { (locationSet) ? <View>
            <Text textAlign="center">You are now signed up! Click below to return to the page to finish purchasing your ticket</Text>
            <Flex justifyContent="center" marginTop="20px" marginBottom="16px">
              <Button onClick={() => navigate(`/event/${locationSet}`)} >Return</Button>
            </Flex>
            </View> : <View>
            <Text textAlign="center">You are now signed up! Please proceed to one of the following pages.</Text>
            <Flex justifyContent="center" marginTop="20px" marginBottom="16px">
              <Button onClick={() => navigate("/")} >Locations</Button>
              <Button onClick={() => navigate("/account")} >My Tickets</Button>
            </Flex>
            </View> }
          </Authenticator>
          <Text>Already have an account? <a href="/signin">Sign in</a></Text>
          </View>
        </Col>
        <Col sm={12} lg={6} className="right">
          <View className="inner">
            <Heading level={2} className="white welcome">Welcome</Heading>
              <Text className="white subheader">Sign in to continue access</Text>
          </View>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;