
import { Text, Flex, Heading, View } from '@aws-amplify/ui-react';
import { Authenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getAllEvents } from "../../helpers/eventsHelpers";
  
const SignIn = () => {
  const navigate = useNavigate();

  return (
    <Container fluid className="signin">
      <Row>
        <Col sm={12} lg={6} className="left">
          <View className="inner">
          <Heading level={1}>Sign In</Heading>
          <Authenticator hideSignUp={true}>
            <Text textAlign="center">You are now signed in! Please proceed to one of the following pages.</Text>
            <Flex justifyContent="center" marginTop="20px" marginBottom="16px">
              <Button onClick={() => navigate("/")} >Locations</Button>
              <Button onClick={() => navigate("/account")} >My Tickets</Button>
            </Flex>
          </Authenticator>
          <Text>Don't have an account? <a href="/signup">Create</a></Text>
          </View>
        </Col>
        <Col sm={12} lg={6} className="right">
          <View className="inner">
            <Heading level={2} className="white welcome">Welcome</Heading>
              <Text className="white subheader">Sign in to continue access</Text>
          </View>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;