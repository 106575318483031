import { Auth, API  } from 'aws-amplify';

export async function listAllUsers() {
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let myInit = { 
      queryStringParameters: {
        "UserPoolId": "us-east-1_3VmWeCi5m"
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  try {
    const rest = await API.get(apiName, path, myInit);
    return rest["Users"];
  } catch (e) {
    console.error(e);
    return false;
  }
}
export async function getUser(username) {
  let apiName = 'AdminQueries';
  let path = '/getUser';
  let myInit = { 
      queryStringParameters: {
        "username": username,
        "UserPoolId": "us-east-1_3VmWeCi5m"
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  try {
    const rest = await API.get(apiName, path, myInit);
    return rest;
  } catch (e) {
    console.error(e);
    return false;
  }
}
export async function addUserToGroup(username, groupname) {
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let myInit = { 
      queryStringParameters: {
        "username": username,
        "groupname": groupname,
        "UserPoolId": "us-east-1_3VmWeCi5m"
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  try {
    const rest = await API.get(apiName, path, myInit);
    return rest;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function listAllOfGroup(name) {
    let apiName = 'AdminQueries';
    let path = '/listUsersInGroup';
    let myInit = { 
        queryStringParameters: {
            "groupname": name,
            "UserPoolId": "us-east-1_3VmWeCi5m"
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    try {
        const rest = await API.get(apiName, path, myInit);
        return rest["Users"];
    } catch (e) {
        console.error(e);
        return false;
    }
    
  }
  
export async function listGroupsForUser(username) {
  let apiName = 'AdminQueries';
  let path = '/listGroupsForUser';
  let myInit = { 
      queryStringParameters: {
          "username": username,
          "UserPoolId": "us-east-1_3VmWeCi5m"
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  try {
      const rest = await API.get(apiName, path, myInit);
      return rest["Users"];
  } catch (e) {
      console.error(e);
      return false;
  }
  
}


export async function checkSignedIn() {
    var return_var = false;
    await Auth.currentAuthenticatedUser().then(user => {
        var groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        var id = user['attributes']['sub'];
        var name = user['attributes']['name'];
        var email = user['attributes']['email'];
        var username = user['username'];
        var level = '';
        if (groups) {
            if (groups.includes('admin')) {
                level = 'admin';
            } else if (groups.includes('managers')) {
                level =  'managers';
            } else if (groups.includes('editors')) {
                level = 'editors';
            } else {
              level = groups;
            }
        }
        return_var = { id: id, level: level, name: name, email: email, username: username}
    }).catch(err => {
        return_var = false;
    });
    return return_var;
}
