/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      locationsID
      Tickets {
        nextToken
        __typename
      }
      qr
      pdfKey
      url
      title
      description
      locationName
      endDate
      status
      userID
      host
      why
      image
      tickets_sold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      locationsID
      Tickets {
        nextToken
        __typename
      }
      qr
      pdfKey
      url
      title
      description
      locationName
      endDate
      status
      userID
      host
      why
      image
      tickets_sold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      locationsID
      Tickets {
        nextToken
        __typename
      }
      qr
      pdfKey
      url
      title
      description
      locationName
      endDate
      status
      userID
      host
      why
      image
      tickets_sold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTickets = /* GraphQL */ `
  mutation CreateTickets(
    $input: CreateTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    createTickets(input: $input, condition: $condition) {
      id
      eventID
      eventName
      status
      expirationDate
      locationsID
      ownerID
      ownerName
      stripeSessionID
      ownerUserName
      locationName
      why
      whouserID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTickets = /* GraphQL */ `
  mutation UpdateTickets(
    $input: UpdateTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    updateTickets(input: $input, condition: $condition) {
      id
      eventID
      eventName
      status
      expirationDate
      locationsID
      ownerID
      ownerName
      stripeSessionID
      ownerUserName
      locationName
      why
      whouserID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTickets = /* GraphQL */ `
  mutation DeleteTickets(
    $input: DeleteTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    deleteTickets(input: $input, condition: $condition) {
      id
      eventID
      eventName
      status
      expirationDate
      locationsID
      ownerID
      ownerName
      stripeSessionID
      ownerUserName
      locationName
      why
      whouserID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLocations = /* GraphQL */ `
  mutation CreateLocations(
    $input: CreateLocationsInput!
    $condition: ModelLocationsConditionInput
  ) {
    createLocations(input: $input, condition: $condition) {
      id
      address
      title
      Events {
        nextToken
        __typename
      }
      userID
      phone
      image
      event_total
      amount_collected
      customer_count
      Tickets {
        nextToken
        __typename
      }
      hours_list
      stripeAccountID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocations = /* GraphQL */ `
  mutation UpdateLocations(
    $input: UpdateLocationsInput!
    $condition: ModelLocationsConditionInput
  ) {
    updateLocations(input: $input, condition: $condition) {
      id
      address
      title
      Events {
        nextToken
        __typename
      }
      userID
      phone
      image
      event_total
      amount_collected
      customer_count
      Tickets {
        nextToken
        __typename
      }
      hours_list
      stripeAccountID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocations = /* GraphQL */ `
  mutation DeleteLocations(
    $input: DeleteLocationsInput!
    $condition: ModelLocationsConditionInput
  ) {
    deleteLocations(input: $input, condition: $condition) {
      id
      address
      title
      Events {
        nextToken
        __typename
      }
      userID
      phone
      image
      event_total
      amount_collected
      customer_count
      Tickets {
        nextToken
        __typename
      }
      hours_list
      stripeAccountID
      createdAt
      updatedAt
      __typename
    }
  }
`;
